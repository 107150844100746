import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Home',
    component:  () => import('@/views/Home.vue'),
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/views/Pay.vue'),
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('@/views/Goods.vue'),
  },
  {
    path: '/goodsConfirm',
    name: 'GoodsConfirm',
    component: () => import('@/views/GoodsConfirm.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
