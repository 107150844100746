<template>
  <div class="pay_container">
    <div class="flex_row p_10 bg_white mt_10">
      <img :src="bannerList[0]" class="order_img" style="object-fit: cover"/>
      <div class="flex_1 ml_10">
        <div style="line-height: 1.5">{{ detail.title }}</div>
        <div class="price_box">
          <div>
            <span v-if="detail.is_deposit==1">定金 ¥{{ detail.deposit_price }}&emsp;</span>
            <span v-else class="fs16">0.00&emsp;</span>
            <span class="cl_nine" style="text-decoration: line-through">{{ detail.original_price }}</span></div>
        </div>
      </div>
    </div>
    <div class="mt_10 cell_box row_center_between" v-if="detail.is_deposit==1">
      <div>
        实付
      </div>
      <div><span class="cl_nine fs12">定金&ensp;</span><span class="cl_red">¥</span><span class="fs16 cl_red">{{ detail.deposit_price }}</span></div>
    </div>
    <div class="mt_10">
      <van-field v-model="form.name" label="学员姓名" placeholder="请输入学员姓名" input-align="right"/>
      <van-cell is-link title="出生日期" @click="showBirthday = true">
        <span v-if="form.birthday" style="color: #333">
          {{ form.birthday }}
        </span>
        <span v-else style="color: #ccc">
          请选择出生日期
        </span>
      </van-cell>
      <van-field v-model="form.phone" type="tel" label="手机号" placeholder="请输入手机号" input-align="right"/>
      <van-field
          v-if="detail.is_sms"
          v-model="form.code"
          center
          type="digit"
          label="验证码"
          placeholder="请输入验证码"
      >
        <template #button>
          <div style="color: #1989fa;cursor: pointer" @click="sendCode">{{ codeMsg }}</div>
        </template>
      </van-field>
    </div>
    <div class="p_10 cl_nine fs12">注：您将购买课程服务，购买之后不支退订、转让、退款。</div>
    <div>
      <div class="pay_footer" v-if="detail.is_deposit==1">
        <div><span class="cl_nine fs12">实付&ensp;</span><span class="cl_red">¥</span><span class="fs16 cl_red">{{ detail.deposit_price }}</span></div>
        <div class="btn_pay" @click="confirmPay">立即付款</div>
      </div>
      <div class="pay_footer" v-else>
        <div></div>
        <div class="btn_pay" @click="confirmJoin">{{ teamId ? '确定参团' : '确定开团' }}</div>
      </div>
    </div>
    <van-popup v-model="showBirthday" position="bottom">
      <van-datetime-picker
          v-model="birthday"
          type="date"
          title="选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="showBirthday=false"
          @confirm="confirmBirthday"
      />
    </van-popup>
  </div>
</template>
<script>
import {getActivityState, isWeiXin, readyWeiXin, setWebTitle} from "@/utils";
import axios from "axios";
import qs from "qs";
import {Toast} from "vant";

export default {
  components: {},
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      birthday: new Date(),
      showBirthday: false,
      openId: "",
      detail: {},
      groupData: {},
      form: {
        name: "",
        phone: "",
        code: "",
        birthday: '',
        invite_code: ""
      },
      groupId: 0,//目前写死
      teamId: 0,//自己修改
      bannerList: [],
      count: 60,
      timer: null,
      codeMsg: "发送验证码"
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null;
    }
  },
  mounted() {
    axios.get(this.API_HOST + '/act/act/getGroupInfo?group_id=' + this.groupId).then(res => {		// 成功处理函数
      if (res.status == 200 && res.data.code == 0) {
        this.detail = res.data.data;
        setWebTitle(this.detail.name)
        if (this.detail.banner) {
          this.bannerList = this.detail.banner.split("|")
        }
      } else {
        Toast(res.data.msg)
      }
    })
    if (isWeiXin()) {
      axios.post(this.API_HOST + `/act/act/checkGroupTeam?group_id=${this.groupId}&openid=${this.openId}${this.teamId ? ('&team_id=' + this.teamId) : ''}`).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          const result = res.data.data
          this.groupData = result;
        } else {
          Toast(res.data.msg)
        }
      })
    }

  },
  created() {
    this.groupId = this.$route.query.group_id || "";
    this.teamId = this.$route.query.team_id || "";
    this.form.invite_code = this.$route.query.invite_code || "";
    //openid不存在，跳转到首页
    const openId = window.localStorage.getItem('openId')
    if (!openId || !isWeiXin()) {
      this.$router.replace({path: '/index', query: {group_id: this.groupId, team_id: this.teamId, invite_code: this.form.invite_code}})
    } else {
      this.openId = openId;
    }
  },
  methods: {
    confirmBirthday(e) {
      const date = e;
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      this.form.birthday = y + '-' + m + '-' + d
      this.showBirthday = false
    },
    sendCode() {
      if (!getActivityState(this.detail)) return
      if (!this.form.phone) return Toast("请输入电话")
      if (this.count != 60) return;
      const loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      axios.post(this.API_HOST + `/apps/common/getVerifyCode?phone=` + this.form.phone).then((res) => {
        loading.clear()
        if (res.status == 200 && res.data.code == 0) {
          Toast("发送成功");
          this.startCount();
        } else {
          Toast(res.data.msg)
        }
      }).catch(e => {
        loading.clear()
      })
    },
    startCount() {
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
          this.codeMsg = this.count + "s后发送";
        } else {
          this.codeMsg = "再来一次";
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null;
          }
          this.count = 60;
        }
      }, 1000);
    },

    confirmPay() {
      if (!getActivityState(this.detail)) return
      if (!this.form.name) return Toast("请输入名字")
      if (!this.form.birthday) return Toast("请选择生日")
      if (!this.form.phone) return Toast("请输入电话")
      if (this.detail.is_sms && !this.form.code) return Toast("请输入验证码")
      let homeUrl = window.location.protocol + "//" + document.domain + '/#/index';

      readyWeiXin(() => {
        let params = {
          stu_birthday: this.form.birthday || "",
          invite_code: this.form.invite_code,
          group_id: this.groupId,
          openid: this.openId,
          stu_name: this.form.name,
          stu_phone: this.form.phone,
          return_url: homeUrl + `?group_id=${this.groupId}&team_id=${this.teamId}`,
          quit_url: homeUrl + `?group_id=${this.groupId}&team_id=${this.teamId}`
        }
        if (this.detail.is_sms) params.verify_code = this.form.code;
        if (this.teamId) params.team_id = this.teamId;
        // 调用获取支付签名接口
        const loading = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        });
        axios.post(this.API_HOST + `/act/act/actPay?` + qs.stringify(params)).then((res) => {
          loading.clear();
          if (res.status == 200 && res.data.code == 0) {
            if(res.data.data.type=='gt'){
              window.location.href=res.data.data.jspay_info
              return
            }
            let payData = JSON.parse(res.data.data.jspay_info)
            let {appId, timeStamp, nonceStr, package: packageStr, signType, paySign} = payData;
            let that = this;
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                  appId, // 微信的appid
                  timeStamp, //时间戳
                  nonceStr, //随机串
                  package: packageStr,  // 订单号
                  signType, //微信签名方式：
                  paySign //微信签名
                },
                function (res) {
                  if (res.err_msg.includes('ok')) {
                    window.location.href = window.location.protocol + "//" + document.domain + `/#/index?group_id=${that.groupId}&team_id=${that.teamId}`
                  } else {
                  }
                }
            );
          } else {
            Toast(res.data.msg)
          }
        }).catch(e => {
          loading.clear();
        })
      })
    },
    confirmJoin() {
      if (!getActivityState(this.detail)) return
      if (!this.form.name) return Toast("请输入名字")
      if (!this.form.phone) return Toast("请输入电话")
      if (this.detail.is_sms && !this.form.code) return Toast("请输入验证码")
      let params = {
        group_id: this.groupId,
        openid: this.openId,
        stu_name: this.form.name,
        stu_phone: this.form.phone,
      }
      if (this.detail.is_sms) params.verify_code = this.form.code;
      if (this.teamId) params.team_id = this.teamId;
      // 调用获取支付签名接口
      const loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      axios.post(this.API_HOST + `/act/act/actPay?` + qs.stringify(params)).then((res) => {
        loading.clear()
        if (res.status == 200 && res.data.code == 0) {
          Toast("报名成功")
          setTimeout(() => {
            this.$router.replace({path: '/index', query: {group_id: this.groupId, team_id: this.teamId, invite_code: this.form.invite_code}})
          }, 1500)
        } else {
          Toast(res.data.msg)
        }
      }).catch(e => {
        loading.clear()
      })
    },

  },
}
</script>
<style lang="less" scoped>
.order_img {
  width: 100px;
  display: block;
  border-radius: 5px;
  height: 80px;
}

.cell_box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px; /* no */
  overflow: hidden;
  color: #323233;
  font-size: 14px; /* no */
  line-height: 24px; /* no */
  background-color: #fff;
}

.pay_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  height: 50px;
  z-index: 100;
  padding-left: 20px;
}

.btn_pay {
  background: #ed424a;
  color: #fff;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
