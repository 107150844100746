import {Toast} from "vant";
import dayjs from "dayjs";


export function getUrlKey(name){//获取url 参数
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
}

export function getShareUrl(groupId,teamId){
  return encodeURIComponent(window.location.href.split('#')[0])
}

export function getGoodsCodeApi(goodsId) {//获取code
  let urlNow = encodeURIComponent(window.location.protocol + "//" + document.domain + '/#/goods');
  window.location.replace(`https://api.footballsaas.com/apps/wxauth/auth?goods_shop_id=${goodsId}&client_type=3&url=${urlNow}`);
  return;
}


export function getCodeApi(groupId,teamId,inviteCode){//获取code
  // let urlNow=urlencode(window.location.href);
  let urlNow=encodeURIComponent(window.location.protocol + "//" + document.domain + '/#/index');
  window.location.replace(`https://api.footballsaas.com/apps/wxauth/auth?group_id=${groupId}&team_id=${teamId}&invite_code=${inviteCode}&client_type=2&url=${urlNow}`);
  return;


  // let urlNow=encodeURIComponent(window.location.href);
  // let urlNow=urlencode(window.location.href);
  // let scope='snsapi_base'; //snsapi_userinfo //静默授权 用户无感知
  // let appid='wxd0c126256f57d0c1';
  // let state="123";
  // let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  // console.log(url)
  // window.location.replace(url);
}


function urlencode (str) {
  str = (str + '').toString();

  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
  replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}


export function isWeiXin() {
  if(['192.168.68.159','localhost'].includes(document.domain))return true;
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    // 是微信浏览器
    return true
  } else {
    // 不是微信浏览器
    return false
  }
}

  export function setWebTitle(t){
    document.title = t;
    var i = document.createElement('iframe');
    i.src = '/favicon.ico';
    i.style.display = 'none';
    i.onload = function() {
      setTimeout(function(){
        i.remove();
      }, 9)
    }
    document.body.appendChild(i);
}

export function readyWeiXin(cb){
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', cb, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', cb);
      document.attachEvent('onWeixinJSBridgeReady', cb);
    }
  } else {
    cb();
  }
}


export function getActivityState({stime,etime}){
  let startTimeStamp= dayjs(stime).valueOf()
  let endTimeStamp= dayjs(etime).valueOf()
  let timeStamp=new Date().getTime()
  if(timeStamp<startTimeStamp){
    Toast('还未到团购开始时间，敬请期待')
    return false
  }
  if(timeStamp>endTimeStamp){
    Toast('团购已结束')
    return false
  }
  return true
}


export function formatRichText(html) {
  let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
    match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
    match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
    match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
    return match;
  });
  newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
    match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
    return match;
  });
  newContent = newContent.replace(/<br[^>]*\/>/gi, '');
  newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
  return newContent;
}
