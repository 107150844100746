import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import {ActionSheet, Button, Calendar, Cell, DatetimePicker, Dialog, Field, Icon, Popup, Toast,Stepper,Picker} from 'vant';

import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false
// Vue.prototype.API_HOST = process.env.NODE_ENV === 'development' ? '' : 'https://api.footballsaas.com';
Vue.prototype.API_HOST = process.env.VUE_APP_API || '';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// 方式一. 通过 Vue.use 注册
// 注册完成后，在模板中通过 <van-button> 或 <VanButton> 标签来使用按钮组件
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(Stepper);
Vue.use(Picker);
