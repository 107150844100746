<template>
  <div v-if="data.id && isShowPage">
    <div>
      <div class="img-cover">
        <img mode="aspectFit" :src="data.cover_img"></img>
      </div>
      <div class="goods-content">
        <div class="title">
          <span class="name">{{ data.goods_show_name }}</span>
          <span class="label">{{ data.goods_group_name }}</span>
        </div>
        <div class="money">
          <div class="box">
            <div class="price">
              <span class="span">￥{{ data.price }}</span>
              <span class="piece">/件</span>
            </div>
            <div class="crossed_price">
              <span>￥{{ data.crossed_price }}</span>
              <div class="line"></div>
            </div>
          </div>
          <div class="share" @click="handleShare">
            <van-icon name="share-o" size="20" color="#999"/>
            <span>分享</span>
          </div>
        </div>
        <div class="spec" @click="showSpec">
          <div class="row_center_between" style="background:#F4F5FA;border-radius: 7px;padding: 10px">
            <div>规格</div>
            <div :class="skuTitle?'':'cl_nine'" style="text-align: right;flex: 1">{{ skuTitle ? skuTitle : '请选择规格' }}</div>
            <van-icon name="arrow" size="16" color="#999"/>
          </div>
        </div>
        <div class="details">
          <div class="details-title">
            <span>商品详情</span>
          </div>
          <div>
            <div v-html="content"></div>
          </div>
        </div>
      </div>

      <div class="gap"></div>
      <div class="footer">
        <div class="box">
          <div class="right" @click="goodsBuy">
            <span>立即购买</span>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="isShow" round position="bottom" closeable>
      <div class="popup-box">
        <div class="top">
          <div class="money">
            <div class="price">
              <span class="span">￥{{ data.price }}</span>
              <span class="piece">/件</span>
            </div>
            <div class="crossed_price">
              <span>￥{{ data.crossed_price }}</span>
              <div class="line"></div>
            </div>
          </div>
          <div class="name" v-if="isStandard==1">
            <span>已选：{{ skuTitle ? skuTitle : '请选择规格' }}</span>
          </div>
        </div>
        <div style="height: 1px;background: #EEEEEE"></div>
        <div class="scroll" style="overflow-y: auto" v-if="isStandard==1">
          <div v-if="data.goods_info">
            <div v-for="(o,i) in data.goods_info[0].option_value_arr" :key="i">
              <div class="title">
                <span>{{ o.title }}</span>
              </div>
              <div class="spec">
                <!-- 禁止点击 spec-button-fff -->
                <div :class="{'spec-button':true,'spec-button-yes':selectArrt[i] == m.id} " v-for="(m,j) in o.attr" :key="j" @click="selectAttr(i,j)">
                  <span>{{ m.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inventory">
          <div>
            <span class="left">数量</span>
            <span class="right">库存{{ skuNum || 0 }}</span>
          </div>
          <div>
            <van-stepper v-model="num" :min="skuNum == 0 ? 0 : 1" :max="skuNum"/>
          </div>
        </div>
        <div class="goods-buy" @click="goodsBuy">
          <div class="buy-button">
            <span>立即购买</span>
          </div>
        </div>
      </div>
      <div class="popup-gap"></div>
    </van-popup>
  </div>
</template>

<script>
import axios from "axios"
import {formatRichText, getGoodsCodeApi, isWeiXin, setWebTitle} from "@/utils";
import {Toast} from "vant";

export default {
  data() {
    return {
      isShow: false,
      isWeiXin: isWeiXin(),
      data: {},
      goodsShopId: 0,//目前写死
      openId: 0,//目前写死
      isShowPage: false,
      content: "",
      selectArrt: [],//选择的规格
      sku: {},//选择的规格
      skuNum: 0,//判断是否有库存
      num: 1,//'购买数量'
      skuTitle: '', //标题
      isStandard: 1, //1有规格，0无规格
    }
  },
  created() {
    setWebTitle('商品详情')
    const userFlag = window.localStorage.getItem('isNewUser')
    const openId = window.localStorage.getItem('openId')
    const hasOpenId = (this.$route.query.openid || openId)
    //微信浏览器，判断是否授权，新用户，没有包含openId
    this.goodsShopId = this.$route.query.goods_shop_id;
    if (isWeiXin()) {
      //本地测试 TODO
      if (['192.168.68.159', 'localhost'].includes(document.domain)) {
        this.openId = 'o48S45uoZNo4HIPv2roKgP3wg9P0';
        window.localStorage.setItem('openId', this.openId)
        this.isShowPage = true;
        return
      }
      if (userFlag == '1' && hasOpenId) {
        this.openId = openId ? openId : this.$route.query.openid;
        window.localStorage.setItem('openId', this.openId)
        this.isShowPage = true;
      } else {
        window.localStorage.setItem('isNewUser', '1')
        getGoodsCodeApi(this.goodsShopId);
      }
    } else {
      this.isShowPage = true;
    }
  },
  computed: {
    skuStockMap() {
      if (!this.data.id) return {}
      const skuMap = {}
      this.data.goods_info[0].sku_list.forEach(item => {
        skuMap[item.attr_id] = item
      })
      return skuMap
    },
  },
  watch: {
    selectArrt: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.isStandard == 1) {
          if (val.every(val => !!val)) {
            const skuItem = this.skuStockMap[val.join(",")]
            if (skuItem) {
              this.skuNum = skuItem.stock
              this.num = 1
              this.skuTitle = skuItem.sku_title
              return
            }
          }
          this.skuNum = 0
          this.num = 0
          this.skuTitle = ''
        }
      }
    }
  },
  mounted() {
    axios.get(this.API_HOST + '/apps/goodsShop/getGoodsShopinfo?goods_shop_id=' + this.goodsShopId).then(res => {		// 成功处理函数
      if (res.status == 200 && res.data.code == 0) {
        this.data = res.data.data
        this.content = formatRichText(this.data.desc)
        this.isStandard = this.data.goods_info[0].is_standard
        if (this.isStandard == 1) {
          this.data.goods_info[0].option_value_arr.forEach(item => {
            this.selectArrt.push(null)
          })
        } else {
          this.skuNum = this.data.goods_info[0].stock
          this.num = this.skuNum > 1 ? 1 : 0
        }

      } else {
        Toast(res.data.msg)
      }
    })
  },
  methods: {
    showSpec() {
      this.isShow = true
    },
    selectAttr(i, j) {
      let goods_info = this.data.goods_info[0]
      this.$set(this.selectArrt, i, goods_info.option_value_arr[i].attr[j].id)
    },
    goodsBuy() {
      if (this.isStandard == 1) {
        if (!this.selectArrt.every(val => !!val)) return Toast('请选择规格')
      }
      if (this.skuNum == 0) return Toast('当前没有库存')
      if (this.num == 0) return Toast('请选择数量')
      let param = {
        goods_shop_id: this.data.id,//商品详情的接口中的id
        attr: {"goods_id": this.data.goods_id, "option_value": this.selectArrt.join(",")},//商品的属性
        goods_id: this.data.goods_id,//goods_info中的id   　　无规格物品时候这个必传
        num: this.num,//数量
      }
      let data = {
        top_school_id: this.data.top_school_id,
        is_standard: this.isStandard,
        sku_title: this.skuTitle,
        cover_img: this.data.cover_img,
        crossed_price: this.data.crossed_price,
        goods_show_name: this.data.goods_show_name,
        spec: this.data.goods_show_name,
        id: this.data.id,
        price: this.data.price,
      }
      this.$router.push(`/goodsConfirm?goods_shop_id=${this.goodsShopId}&param=${JSON.stringify(param)}&data=${JSON.stringify(data)}`)
    },
    handleShare() {
      Toast("请点击右上角分享按钮");
    }
  },
}
</script>
<style lang="less" scoped>
.img-cover {
  img {
    width: 100%;
    height: 350px;
    display: block;
    object-fit: cover;
  }
}

.goods-content {
  width: 100%;
  background-color: #ffffff;

  .title {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .label {
      border: 1px solid #FF6934;
      font-size: 12px;
      padding: 0px 4px;
      margin-right: 5px;
      color: #FF6934;
      margin-left: 5px;
    }

    .name {
      font-size: 16px;
    }
  }

  .money {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;

    .span {
      font-size: 16px;
      color: #FF6934;
    }

    .piece {
      font-size: 11px;
      color: #999999;
    }

    .box {
      display: flex;
      align-items: center;
    }

    .crossed_price {
      position: relative;
      margin-left: 10px;

      span {
        font-size: 16px;
        color: #999999;
      }

      .line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #999999;
        width: 100%;
        color: #999999;
      }
    }

    .share {
      display: flex;
      align-items: center;

      img {
        width: 17px;
        height: 17px;
        margin-right: 3px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  .spec {
    width: 100%;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;

    .value {
      font-size: 16px;
    }

    .color-ccc {
      color: #CCCCCC;
    }
  }

  .details {
    width: 100%;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;

    .details-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 3px;

      .label {
        color: #666666;
        font-size: 16px;
        margin-right: 3px;
      }

      .value {
        color: #666666;
        font-size: 16px;
      }
    }
  }
}

.gap {
  height: 60px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px -1px 6px #dddddd;

  .box {
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    box-sizing: border-box;

    .right {
      margin: 0 auto;
      width: 90%;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to right, #00A870 0%, #00A870 100%);
      border-radius: 25px;

      span {
        font-size: 17px;
        color: #ffffff;
      }
    }
  }
}

.popup-box {
  width: 100%;
  height: 400px;
  position: relative;

  .top {
    padding: 15px;
    box-sizing: border-box;

    .money {
      display: flex;
      align-items: center;

      .span {
        font-size: 16px;
        color: #FF6934;
      }

      .piece {
        font-size: 11px;
        color: #999999;
      }

      .crossed_price {
        position: relative;
        margin-left: 10px;

        span {
          font-size: 16px;
          color: #999999;
        }

        .line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #999999;
          width: 100%;
          color: #999999;
        }
      }
    }

    .name {
      font-size: 17px;
      margin-top: 8px;
    }
  }

  .scroll {
    width: 100%;
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
    height: 180px;

    .title {
      font-size: 17px;
    }

    .spec {
      width: 100%;
      margin-top: 10px;

      .spec-button {
        border-radius: 5px;
        padding: 4px 15px;
        border: 1px solid #DDDDDD;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;

        span {
          font-size: 17px;
        }
      }

      .spec-button-fff {
        background-color: #F6F7F8;
        color: #D4DFF8;
      }

      .spec-button-yes {
        border: 1px solid #FF6934;
        color: #FF6934;
      }
    }
  }

  .inventory {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 17px;
    }

    .right {
      font-size: 17px;
      color: #999999;
      margin-left: 5px;
    }
  }

  .goods-buy {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 0;

    .buy-button {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to right, #00A870 0%, #00A870 100%);
      border-radius: 8px;

      span {
        font-size: 19px;
        color: #ffffff;
      }
    }
  }
}
</style>
