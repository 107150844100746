(function(win) {
  var tid;

  function refreshRem() {
    let designSize = 750; // 设计图尺寸
    let html = document.documentElement;
    let wW = html.clientWidth; // 窗口宽度
    if (wW > 750) wW = 750;
    let rem = wW / designSize * 200;
    document.documentElement.style.fontSize = rem + 'px';
  }
  win.addEventListener('resize', function() {
    clearTimeout(tid);
    tid = setTimeout(refreshRem, 300);
  }, false);
  refreshRem();
})(window);
