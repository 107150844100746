<template>
  <div>
    <div class="main-goods">
      <div class="item">
        <img class="item-images" style="object-fit: cover" :src="detail.cover_img"/>
        <div class="item-content">
          <div class="item-title">
            <span class="name">{{ detail.goods_show_name }}</span>
          </div>
          <div class="spec" v-if="detail.is_standard==1">
            <span>{{ detail.sku_title }}</span>
          </div>
          <div class="item-money">
            <span class="money">￥{{ detail.price }}</span>
            <span class="qty">/件</span>
          </div>
        </div>
      </div>
      <div class="total-money">
        <span class="qty">共{{ param.num }}件</span>
        <span class="money">小计：￥{{ detail.price * param.num }}</span>
      </div>
    </div>
    <div style="height: 10px;background: #f9f9f9"></div>

    <div class="mt_10">
      <van-field
          readonly
          clickable
          name="picker"
          :value="selectSchoolTitle"
          input-align="right"
          label="请选择校区"
          placeholder="点击选择校区"
          @click="showSchoolPicker = true"
      />
      <van-field v-model="form.name" label="学员姓名" placeholder="请输入学员姓名" input-align="right"/>
      <van-cell is-link title="出生日期" @click="showBirthday = true">
        <span v-if="form.birthday" style="color: #333">
          {{ form.birthday }}
        </span>
        <span v-else style="color: #ccc">
          请选择出生日期
        </span>
      </van-cell>
      <van-field v-model="form.phone" type="tel" label="手机号" placeholder="请输入手机号" input-align="right"/>
      <van-field
          input-align="right"
          v-model="form.code"
          center
          type="digit"
          label="验证码"
          placeholder="请输入验证码"
      >
        <template #button>
          <div style="color: #1989fa;cursor: pointer" @click="sendCode">{{ codeMsg }}</div>
        </template>
      </van-field>
    </div>
    <div>
      <div class="pay_footer">
        <div><span class="cl_nine fs12">实付&ensp;</span><span class="cl_red">¥</span><span class="fs16 cl_red">{{ detail.price * param.num }}</span></div>
        <div class="btn_pay" @click="confirmPay">立即付款</div>
      </div>
    </div>
    <van-popup v-model="showBirthday" position="bottom">
      <van-datetime-picker
          v-model="birthday"
          type="date"
          title="选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="showBirthday=false"
          @confirm="confirmBirthday"
      />
    </van-popup>
    <van-popup v-model="showSchoolPicker" position="bottom">
      <van-picker
          show-toolbar
          :columns="schoolList.map(item=>item.name)"
          @confirm="onConfirm"
          @cancel="showSchoolPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import {isWeiXin, readyWeiXin, setWebTitle} from "@/utils";
import axios from "axios";
import {Toast} from "vant";

export default {
  components: {},
  data() {
    return {
      showSchoolPicker: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      birthday: new Date(),
      showBirthday: false,
      openId: "",
      param: {},
      detail: {},
      form: {
        name: "",
        phone: "",
        code: "",
        birthday: '',
        school_id: ""
      },
      selectSchoolTitle: "",
      schoolList: [],
      goodsShopId: 0,
      count: 60,
      timer: null,
      codeMsg: "发送验证码"
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null;
    }
  },
  mounted() {
    axios.get(this.API_HOST + '/apps/goodsShop/getSchoolList?top_school_id=' + this.detail.top_school_id).then(res => {		// 成功处理函数
      if (res.status == 200 && res.data.code == 0) {
        this.schoolList = res.data.data;
      } else {
        Toast(res.data.msg)
      }
    })
  },
  created() {
    setWebTitle('确认订单')
    this.goodsShopId = this.$route.query.goods_shop_id || "";
    this.detail = JSON.parse(this.$route.query.data)
    this.param = JSON.parse(this.$route.query.param)
    const openId = window.localStorage.getItem('openId')
    if (!openId || !isWeiXin()) {
      this.$router.replace({path: '/goods', query: {goods_shop_id: this.goodsShopId}})
    } else {
      this.openId = openId;
    }
  },
  methods: {
    onConfirm(value, index) {
      this.selectSchoolTitle = value;
      this.form.school_id = this.schoolList[index].id
      this.showSchoolPicker = false;
    },
    confirmBirthday(e) {
      const date = e;
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      this.form.birthday = y + '-' + m + '-' + d
      this.showBirthday = false
    },
    sendCode() {
      if (!this.form.phone) return Toast("请输入电话")
      if (this.count != 60) return;
      const loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      axios.post(this.API_HOST + `/apps/common/getVerifyCode?phone=` + this.form.phone).then((res) => {
        loading.clear()
        if (res.status == 200 && res.data.code == 0) {
          Toast("发送成功");
          this.startCount();
        } else {
          Toast(res.data.msg)
        }
      }).catch(e => {
        loading.clear()
      })
    },
    startCount() {
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
          this.codeMsg = this.count + "s后发送";
        } else {
          this.codeMsg = "再来一次";
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null;
          }
          this.count = 60;
        }
      }, 1000);
    },

    confirmPay() {
      if (!this.form.school_id) return Toast("请选择校区")
      if (!this.form.name) return Toast("请输入名字")
      if (!this.form.birthday) return Toast("请选择生日")
      if (!this.form.phone) return Toast("请输入电话")
      if (!this.form.code) return Toast("请输入验证码")
      let params = {
        school_id: this.form.school_id,
        stu_birthday: this.form.birthday || "",
        goods_shop_id: this.param.goods_shop_id,
        goods_id: this.param.goods_id,
        num: this.param.num,
        openid: this.openId,
        stu_name: this.form.name,
        stu_phone: this.form.phone,
        verify_code: this.form.code
      }
      if (this.detail.is_standard == 1) {
        params.attr = JSON.stringify(this.param.attr)
      }
      readyWeiXin(() => {
        // 调用获取支付签名接口
        const loading = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        });
        axios.post(this.API_HOST + `/apps/goodsShop/createGoodsOrder`, params).then((res) => {
          loading.clear();
          if (res.status == 200 && res.data.code == 0) {
            if(res.data.data.type=='gt'){
              window.location.href=res.data.data.jspay_info
              return
            }
            let payData = JSON.parse(res.data.data.jspay_info)
            let {appId, timeStamp, nonceStr, package: packageStr, signType, paySign} = payData;
            let that = this;
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                  appId, // 微信的appid
                  timeStamp, //时间戳
                  nonceStr, //随机串
                  package: packageStr,  // 订单号
                  signType, //微信签名方式：
                  paySign //微信签名
                },
                function (res) {
                  if (res.err_msg.includes('ok')) window.location.href = window.location.protocol + "//" + document.domain + `/#/goods?goods_shop_id=${that.goodsShopId}`
                }
            );
          } else {
            Toast(res.data.msg)
          }
        }).catch(e => {
          loading.clear();
        })
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-goods {
  padding-top: 10px;
  width: 100%;
  background-color: #FFFFFF;

  .item {
    width: 355px;
    height: 105px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    box-sizing: border-box;

    .item-images {
      width: 90px;
      height: 90px;
    }

    .item-content {
      width: 250px;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item-title {
        width: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;

        .name {
          font-size: 16px;
        }
      }

      .spec {
        padding: 0 10px;
        box-sizing: border-box;

        span {
          padding: 2px 8px;
          box-sizing: border-box;
          background-color: #F6F7F8;
          color: #9B9B9B;
          font-size: 16px;
          border-radius: 4px;
        }
      }

      .item-money {
        padding: 3px 10px;
        box-sizing: border-box;
        display: flex;

        .money {
          font-size: 16px;
          color: #FF6934;
        }

        .qty {
          color: #cccccc;
          font-size: 12px;
          margin-top: 4px;
          margin-left: 1px;
        }
      }
    }
  }

  .total-money {
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    .qty {
      font-size: 15px;
      color: #B2B2B2;
      margin-right: 10px;
    }

    .money {
      font-size: 15px;
      color: #FA8F6A;
    }
  }
}

.order_img {
  width: 100px;
  display: block;
  border-radius: 5px;
  height: 80px;
}

.cell_box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px; /* no */
  overflow: hidden;
  color: #323233;
  font-size: 14px; /* no */
  line-height: 24px; /* no */
  background-color: #fff;
}

.pay_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  height: 50px;
  z-index: 100;
  padding-left: 20px;
}

.btn_pay {
  background: #ed424a;
  color: #fff;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
