<template>
  <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide v-for="(item,index) in bannerList" :key="index">
      <img :src="item" class="img" style="object-fit: cover"/>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'carrousel',
  data() {
    return {
      bannerList:[],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        // Some Swiper option/callback...
      }
    }
  },
  props: {
    list: {
      type: Array,
      default: ()=>[]
    },
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    // this.swiper.slideTo(3, 1000, false)
  },
  watch:{
    list:{
      immediate:true,
      deep:true,
      handler(){
        this.bannerList=this.list;
      }
    }
  }
}
</script>
<style scoped="less" scoped>
.img{
  width: 100%;
  height: 290px;
}
</style>
