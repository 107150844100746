<template>
  <div v-if="form.id && isShowPage">
    <div class="banner">
      <swiper :list="bannerList"></swiper>
      <div class="people_num row_center_between">
        <span class="cl_yellow flex_1 center">{{ form.view_num || 0 }}<i class="cl_white">人查看</i></span>
        <span class="cl_yellow flex_1 center">{{ form.sign_num || 0 }}<i class="cl_white">人报名</i></span>
      </div>
    </div>
    <div class="index_price">
      <div class="left">
        <div class="current bold">
          <div v-if="act_json.length==1">
            <span class="fs20">¥</span>
            <span class="price_con">{{ act_json[0].act_price }}</span>
          </div>
          <div v-if="act_json.length>1">
            <span class="fs20">¥</span>
            <span class="price_con">{{ act_json[act_json.length - 1].act_price }}</span>
            <span>起</span>
          </div>
        </div>
        <div>
          <div class="orign">原价¥{{ form.original_price }}</div>
          <div style="margin-left: 15px" v-if="form.is_deposit==1 && form.is_deposit_type==2">定金：{{ form.deposit_price }}</div>
        </div>
      </div>
      <div class="time" v-if="['wait','loading'].includes(pnTimeStatus)">
        <div>{{ pnTimeStatus == 'wait' ? '距开始仅剩' : '距结束仅剩' }}</div>
        <div class="con center" v-if="isHasDay">
          <span>{{ timeContent.d }}</span>天<span>{{ timeContent.h }}</span>时<span>{{ timeContent.m }}</span>分
        </div>
        <div class="con center" v-else>
          <span>{{ timeContent.h }}</span>时<span>{{ timeContent.m }}</span>分<span>{{ timeContent.s }}</span>秒
        </div>
      </div>
      <div class="time" v-if="pnTimeStatus=='end'">
        <div>已结束</div>
      </div>
    </div>
    <div class="index_title">
      <div class="title">
        <i v-if="act_json.length==1">{{ act_json[0].act_num }}人团</i>
        <i v-if="act_json.length>1">多人拼团</i>
        <span class="bold fs16">{{ form.title }}</span>
      </div>
      <div class="row_center_between mt_15 num pb_10" style="color: #c97336;">
        <div class="center flex_1">开团数:{{ groupData.team_num || 0 }}</div>
        <span class="line"></span>
        <div class="center flex_1">已成团:{{ groupData.team_success_num || 0 }}</div>
        <span class="line"></span>
        <div class="center flex_1">成团上限:0</div>
      </div>
    </div>
    <div class="mt_10">
      <img src="~@/assets/pin_tuan_desc.png" style="width: 100%;display: block;"/>
    </div>
    <div class="mt_10 index_desc" style="position: relative" v-if="teamId">
      <div>
        <img src="~@/assets/pn_loading.png" class="pn_statue_icon" v-if="pnTimeStatus!='end' && teamLen<maxActJson.act_num"/>
        <img src="~@/assets/pt_success.png" class="pn_statue_icon" v-if="pnTimeStatus!='end' && teamLen>=maxActJson.act_num"/>
      </div>
      <div class="cl_red center" v-if="isMyTeam">我的团</div>
      <div class="cl_red center" v-if="!isMyTeam">TA的团</div>
      <div class="mt_20">
        <div v-if="teamLen<act_json[0].act_num" class="center">
          当前<span class="cl_red">{{ teamLen }}</span>人,还差<span class="cl_red">{{ act_json[0].act_num - teamLen }}</span>人可成团
        </div>
        <div v-if="teamLen>=act_json[0].act_num" class="center">
          当前<span class="cl_red">{{ teamLen }}</span>人,可享受拼团价{{ actJsonCheck.act_price }}元
        </div>
      </div>
      <div class="center" style="flex-wrap: wrap;align-items: flex-start">
        <div v-for="(item,index) in curretTeamList" :key="index" class="default_avatar_wrap">
          <img src="~@/assets/default_stu.png" class="default_avatar"/>
          <div class="in1line mt_5" style="text-align: center">{{ item.stu_name }}</div>
          <div class="in1line" style="text-align: center;color:#fe113b" v-if="item.is_team_head">(团长)</div>
        </div>
      </div>
      <div class="mt_20">
        <div class="flex_row" style="align-items: center">
          <div class="circle"></div>
          <div :style="{width:240+'px'}" class="group_bg cl_red none">达到{{ actJsonCheck.act_num }}人</div>
          <div class="cl_red  ml_5">{{ actJsonCheck.act_price ? actJsonCheck.act_price : "00" }}元</div>
        </div>
      </div>
      <div @click="shareTuan" class="btn_mid_share">
        一键找好友参团
      </div>
    </div>

    <div class="mt_10 index_desc" v-if="act_json.length>1">
      <div class="cl_red center">拼团特惠</div>
      <div class="center mt_5">{{ form.course_title }}</div>
      <div v-for="(item,index) in act_json" class="mt_20">
        <!--        <div-->
        <!--            class="cl_nine"-->
        <!--            v-if="index>0 && (teamLen>act_json[index-1].act_num && teamLen<item.act_num)"-->
        <!--            style="padding-bottom: 20px;margin-left: 20px;"-->
        <!--        >还差{{item.act_num-teamLen}}人</div>-->
        <div class="flex_row" style="align-items: center">
          <div class="circle"></div>
          <div :style="{width:240-index*20+'px'}" class="group_bg cl_red" :class="{'none':teamLen<item.act_num}">{{ item.act_num }}人团</div>
          <div class="cl_red  ml_5">{{ item.act_price ? item.act_price : "00" }}元</div>
        </div>
      </div>
    </div>
    <div class="index_desc mt_10">
      <div class="cl_red center">课程描述</div>
      <div>{{ form.course_title }}</div>
      <div class='con' v-html="form.course_desc"></div>
      <div>
        <img :src="item" style="width: 100%;" v-for="(item,index) in courseImgList" :key="index" class="mt_10"/>
      </div>
    </div>
    <div class="index_desc mt_10">
      <div class="cl_red center">活动说明</div>
      <div class='con' v-html="form.desc">
      </div>
    </div>
    <div class="index_desc mt_10">
      <div class="cl_red center">联系方式</div>
      <div class='con' v-html="form.link_desc">
      </div>
      <div v-if="form.qr_code" class="mt_10">
        <img :src="form.qr_code" style="width: 100%">
      </div>
    </div>
    <div class="index_desc mt_10">
      <div class="cl_red center">机构介绍</div>
      <div class='con' v-html="form.institution_desc">
      </div>
    </div>
    <div class="index_footer"></div>

    <!--    系统团只能开团 ,没有参加-->
    <div class="index_btn_fixed row_center_between" v-if="!isJoin && !teamId">
      <div class="flex_1 center red" @click="openTuan">我要开团</div>
    </div>
    <div class="index_btn_fixed row_center_between" v-else-if="isJoin">
      <!--      我的队伍，并且没有满员-->
      <div class="flex_1 center red" @click="shareTuan" v-if="isMyTeam && !isFull">找好友一起拼</div>
      <!--      我的队伍，满员-->
      <div class="flex_1 center red" v-if="isMyTeam && isFull">队伍已满</div>
      <!--      不是我的队伍-->
      <div class="flex_1 center red" @click="jumpMyTuan" v-if="!isMyTeam">查看我的拼团</div>
    </div>
    <div class="index_btn_fixed row_center_between" v-else>
      <!--      没有参加，未满员-->
      <!--      <div class="flex_1 center white" @click="openTuan" v-if="!isFull">我要开团</div>-->
      <div class="flex_1 center red" @click="joinTuan" v-if="!isFull">参与此团</div>
      <!--没有参加，并且本队已满-->
      <div class="flex_1 center red" v-if="isFull" @click="openTuan">此团已满,找好友拼团</div>
    </div>

    <van-dialog v-model="visible.success" title="提示" show-cancel-button>
      <div style="text-align: center;font-size: 14px" class="py_15 cl_nine">
        <div>您已成功报名</div>
        <div class="mt_10">快去找好友一起拼团吧！</div>
      </div>
    </van-dialog>
    <van-action-sheet v-model="visible.showShare" :actions="actions" @select="onSelect" cancel-text="取消"
                      close-on-click-action
    />
    <div class="kaituan_wrap">
      <img src="~@/assets/kaituan.png" class="kaituan" @click="openKaiTuan"/>
    </div>
    <van-popup v-model="visible.kaiTuan" :style="{ borderRadius: '8px' }" closeable>
      <img :src="posterSrc" class="posterSrc">
      <div style="width:90%;margin:0 auto 15px">
        <div style="color: #999;font-size: 14px;text-align: center">*长按图片保存或分享</div>
        <van-button type="danger" style="width: 100%;margin-top: 5px" size="small" round @click="visible.kaiTuan=false">确定</van-button>
      </div>
    </van-popup>

    <!--    <van-dialog v-model="visible.kaiTuan" title="一起加入我的团吧" theme='round-button'>-->
    <!--      <div class="poster-shadow" v-if="form.id">-->
    <!--        <img :src="form.banner.split('|')[0]" width="100%" style="border-radius: 5px;display: block"/>-->
    <!--        <div class="title mt_10">-->
    <!--          {{ form.title }}-->
    <!--        </div>-->
    <!--        <div class="row_center_between">-->
    <!--          <div class="flex_1 position">-->
    <!--            <qrcode :value="qrCodeUrl" :options="{ width: 120 }" v-if="qrCodeUrl" :margin="0"></qrcode>-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <p>长按识别</p>-->
    <!--            <p style="color: #d0021b">领取优惠</p>-->
    <!--          </div>-->
    <!--          <div style="margin-left: 10px">-->
    <!--            <img src="~@/assets/handle_code.png" width="50" height="50"/>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div style="text-align: center;margin-top: 10px">-->
    <!--          <span>下载海报</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </van-dialog>-->
    <div style="position: fixed;left: -5000px;top:-5000px;">
      <div class="poster-shadow" v-if="form.id" id="captureWrap">
        <div style="text-align: center;padding: 20px 0 10px;font-weight: bold;font-size: 16px">一起加入{{ qrCodeName }}团吧</div>
        <img :src="getTimeStamp(form.banner.split('|')[0])" width="100%" style="object-fit: cover; border-radius: 5px;display: block" crossorigin="anonymous"/>
        <div class="title mt_10">
          {{ form.title }}
        </div>
        <div class="row_center_between">
          <div class="flex_1 position">
            <qrcode :value="qrCodeUrl" :options="{ width: 120 }" v-if="qrCodeUrl" :margin="0"></qrcode>
          </div>
          <div>
            <p>长按识别</p>
            <p style="color: #d0021b">领取优惠</p>
          </div>
          <div style="margin-left: 10px">
            <img src="~@/assets/handle_code.png" width="50" height="50"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Swiper from "@/components/swiper/index.vue";
import axios from "axios"
import {getActivityState, getCodeApi, getShareUrl, isWeiXin, setWebTitle} from "@/utils";
import {Toast} from "vant";
import html2canvas from 'html2canvas';

export default {
  components: {
    Swiper,
  },
  name: "detail-preview",
  data() {
    return {
      isWeiXin: isWeiXin(),
      visible: {
        showShare: false,
        success: false,
        kaiTuan: false
      },
      timeContent: {
        d: '00',
        h: "00",
        m: "00",
        s: "00",
      },
      posterSrc: '',
      isHasDay: true,
      qrCodeUrl: '',
      qrCodeName: '',
      bannerList: [],
      courseImgList: [],
      act_json: [],
      detail: {},
      form: {},
      wxCode: "",
      groupId: 0,//目前写死
      openId: 0,//目前写死
      teamId: 0,//自己修改
      inviteCode: "",
      groupData: {},
      pnTimeStatus: '', //wait 还未开始   loading 进行中    end 结束
      isJoin: false, //是否参与
      isFull: false, //0是未满员，1是满员
      isMyTeam: false, //是否我的队伍
      curretTeamList: [],
      actions: [{name: '分享到朋友圈'}, {name: '分享给好友'}],
      isShowPage: false,
      timer: null
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  created() {
    const userFlag = window.localStorage.getItem('isNewUser')
    const openId = window.localStorage.getItem('openId')
    const hasOpenId = (this.$route.query.openid || openId)
    //微信浏览器，判断是否授权，新用户，没有包含openId
    this.groupId = this.$route.query.group_id;
    this.teamId = this.$route.query.team_id || "";
    this.inviteCode = this.$route.query.invite_code || "";
    if (isWeiXin()) {
      //本地测试
      if (['192.168.68.159', 'localhost'].includes(document.domain)) {
        this.openId = 'o48S45uoZNo4HIPv2roKgP3wg9P0';
        window.localStorage.setItem('openId', this.openId)
        this.isShowPage = true;
        return
      }
      if (userFlag == '1' && hasOpenId) {
        this.openId = openId ? openId : this.$route.query.openid;
        window.localStorage.setItem('openId', this.openId)
        this.isShowPage = true;
      } else {
        window.localStorage.setItem('isNewUser', '1')
        getCodeApi(this.groupId, this.teamId,this.inviteCode);
      }
    } else {
      this.isShowPage = true;
      // Toast("请在微信浏览器打开")
    }
  },
  computed: {
    //最大
    maxActJson() {
      let act_json = this.act_json;
      if (act_json.length > 0) return act_json[act_json.length - 1]
      return {}
    },
    //当前属于哪个阶段
    actJsonCheck() {
      let act_json = this.act_json;
      if (this.act_json.length > 0) {
        if (this.teamLen >= this.act_json[act_json.length - 1].act_num) return this.act_json[act_json.length - 1];
        for (let i = 0; i < act_json.length; i++) {
          if (this.teamLen >= act_json[i].act_num && this.teamLen < act_json[i + 1].act_num) {
            return act_json[i];
          }
        }
        return act_json[0]
      }
      return {}
    },
    //队伍长度
    teamLen() {
      return this.curretTeamList.length
    }
  },
  mounted() {
    axios.get(this.API_HOST + '/act/act/getGroupInfo?group_id=' + this.groupId).then(res => {		// 成功处理函数
      if (res.status == 200 && res.data.code == 0) {
        this.setData(res.data.data)
      } else {
        Toast(res.data.msg)
      }
    })
    //浏览数据调用
    axios.get(this.API_HOST + '/act/act/viewNum?group_id=' + this.groupId).then(res => {
    })
    if (isWeiXin() && this.isShowPage) {
      //检查你是否参与过此团购活动
      axios.post(this.API_HOST + `/act/act/checkGroupTeam?group_id=${this.groupId}&openid=${this.openId}${this.teamId ? ('&team_id=' + this.teamId) : ''}`).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          const result = res.data.data
          if (result.act_json) {
            this.act_json = JSON.parse(result.act_json).sort((a, b) => {
              return a.act_num > b.act_num ? 1 : -1;
            })
          }
          this.curretTeamList = result.current_team_list;
          this.groupData = result;
          if (this.groupData.is_group) this.isJoin = true;
          if (this.teamId && this.groupData.team_id) this.isMyTeam = true;
          if (this.curretTeamList.length >= this.act_json[this.act_json.length - 1].act_num) this.isFull = true;
          this.onSelect();
        } else {
          Toast(res.data.msg)
        }
      })
      //分享注入
      axios.get(this.API_HOST + `/act/act/getWxTicket?group_id=${this.groupId}&url=${getShareUrl(this.groupId, this.teamId)}`).then(res => {		// 成功处理函数
        if (res.status == 200 && res.data.code == 0) {
          const result = res.data.data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: result.appId, // 必填，公众号的唯一标识
            timestamp: result.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.nonceStr, // 必填，生成签名的随机串
            signature: result.signature,// 必填，签名
            jsApiList: [
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ] // 必填，需要使用的 JS 接口列表
          });
        } else {
          Toast(res.data.msg)
        }
      })
    }
  },
  methods: {
    downLoadPoster() {
      const domObj = document.getElementById('captureWrap')
      html2canvas(domObj, {
        backgroundColor: null,
        useCORS: true,
        allowTaint: false,
        logging: false,
      }).then((canvas) => {
        //成功后的回调函数
        this.posterSrc = canvas.toDataURL('image/png');
        this.visible.kaiTuan = true;
      }).catch(e=>{
        Toast('errorr:'+e?e.toString():'');
      })
    },
    getTimeStamp(url) {
      return url + "?" + new Date().getTime();
    },
    openKaiTuan() {
      if (!isWeiXin()) return Toast('请在微信浏览器打开')
      if (!getActivityState(this.form)) return
      axios.post(this.API_HOST + `/act/act/shareOpenGroup?group_id=${this.groupId}&openid=${this.openId}`).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          const result = res.data.data
          this.qrCodeUrl = window.location.protocol + "//" + document.domain + `/#/index?group_id=${result.group_id}&team_id=${result.team_id}&invite_code=${this.inviteCode}`;
          this.qrCodeName = result.team_head_name
          this.$nextTick(() => {
            this.downLoadPoster()
          })
        } else {
          Toast((res && res.data)?res.data.msg:'error back')
        }
      }).catch(error=>{
        if (error.response) {
          // 服务器响应了一个非 2xx 的状态码
          Toast(`Error: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
          // 请求已经发出，但没有收到响应
          Toast('No response received from the server.');
        } else {
          // 发生了一些错误，在设置请求时触发
          Toast(`Request error: ${error.message}`);
        }
      })
    },
    onSelect() {
      let imgUrl = this.bannerList[0];
      let linkUrl = window.location.protocol + "//" + document.domain + `/#/index?group_id=${this.groupId}` + (this.groupData.team_id ? ('&team_id=' + this.groupData.team_id) : '') + (this.inviteCode ? ('&invite_code=' + this.inviteCode) : '');
      let desc = this.form.title;
      let title = this.form.name;
      wx.ready(function () {
        // 微信分享的数据
        wx.updateTimelineShareData({
          "imgUrl": imgUrl,
          "link": linkUrl,
          "title": title,
          success: function () {
          },
          fail: function (err) {
            // Toast(JSON.stringify(err))
          }
        });
        //分享给朋友
        wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {
          },
          fail: function (err) {
            // Toast(JSON.stringify(err))
          }
        });
      });
    },
    shareTuan() {
      // this.visible.showShare = true;
      Toast("请点击右上角分享按钮")
      axios.get(this.API_HOST + '/act/act/forwardNum?group_id=' + this.groupId).then(res => {
      })
    },
    joinTuan() {
      if (!isWeiXin()) return Toast("请到微信浏览器报名")
      if (!getActivityState(this.form)) return
      //参团
      this.$router.push(`/pay?group_id=${this.groupId}&team_id=${this.teamId}&invite_code=${this.inviteCode}`)
    },
    jumpMyTuan() {
      if (!isWeiXin()) return Toast("请到微信浏览器报名")
      if (!getActivityState(this.form)) return
      //更新当前页
      this.$router.replace({path: '/index', query: {group_id: this.groupId, team_id: this.groupData.team_id,invite_code:this.inviteCode}}, () => {
        window.location.reload()
      })
    },
    openTuan() {
      if (!isWeiXin()) return Toast("请到微信浏览器报名")
      if (!getActivityState(this.form)) return
      //开团
      this.$router.push(`/pay?group_id=${this.groupId}&invite_code=${this.inviteCode}`)
    },
    formatRichText(html) {
      let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
        return match;
      });
      newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
        return match;
      });
      newContent = newContent.replace(/<br[^>]*\/>/gi, '');
      newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
      return newContent;
    },
    initCountTime() {
      if (this.pnTimeStatus == 'end') return;
      let startTimeStamp = dayjs(this.form.stime).valueOf()
      let endTimeStamp = dayjs(this.form.etime).valueOf()
      let currentTimeStamp = new Date().getTime()
      let selectTimeStamp = new Date().getTime()
      if (currentTimeStamp < startTimeStamp) {
        this.pnTimeStatus = 'wait'
        selectTimeStamp = startTimeStamp
      } else if (currentTimeStamp > endTimeStamp) {
        this.pnTimeStatus = 'end'
        return;
      } else {
        selectTimeStamp = endTimeStamp
        this.pnTimeStatus = 'loading'
      }
      let timeStamp = (selectTimeStamp - currentTimeStamp) / 1000;
      if (timeStamp > 0) {
        let d = Math.floor(timeStamp / (60 * 60 * 24));
        let h = Math.floor((timeStamp - d * (60 * 60 * 24)) / (60 * 60));
        let m = Math.floor((timeStamp - d * (60 * 60 * 24) - h * (60 * 60)) / 60);
        let s = Math.floor((timeStamp - d * (60 * 60 * 24) - h * (60 * 60) - m * 60));
        this.isHasDay = d > 0
        this.timeContent = {
          d: d < 10 ? "0" + d : d,
          h: h < 10 ? "0" + h : h,
          m: m < 10 ? "0" + m : m,
          s: s < 10 ? "0" + s : s,
        }
        this.getCountTime();
      }
    },
    //倒计时
    getCountTime() {
      if (this.pnTimeStatus == 'end') return;
      this.timer = setTimeout(() => {
        this.initCountTime()
      }, 1000)
    },
    setData(detail) {
      setWebTitle(detail.name)
      detail.deposit_price = detail.deposit_price ? parseFloat(detail.deposit_price) : 0;
      this.form = detail;
      if (this.form.institution_desc) {
        this.form.institution_desc = this.formatRichText(this.form.institution_desc)
      }

      if (detail.act_json) {
        this.act_json = JSON.parse(detail.act_json).sort((a, b) => {
          return a.act_num > b.act_num ? 1 : -1;
        })
      }

      if (detail.banner) {
        this.bannerList = detail.banner.split("|")
      }
      if (detail.courde_img) {
        this.courseImgList = detail.courde_img.split("|")
      }
      this.initCountTime();
    },
  },
}
</script>
<style lang="less" scoped>
.pn_statue_icon {
  width: 65px;
  height: 70px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.default_avatar {
  width: 43px;
  height: 43px;
  display: block;
  margin: 0 auto;
}

.default_avatar_wrap {
  text-align: center;
  margin-top: 15px;
  width: 70px;
  padding: 0 8px;
}

.btn_mid_share {
  margin: 30px auto 0;
  width: 250px;
  background: #fe113b;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituan_wrap {
  position: fixed;
  right: 0;
  bottom: 70px;

  .kaituan {
    width: 75px;
    height: 75px;
  }
}

.poster-shadow {
  border-radius: 10px;
  width: 300px;
  //margin:0 20px;
  //padding: 0 40px;
  //width: 303px;
  //min-height: 399px;
  //margin: auto;
  padding: 8px 20px;
  background: #fff;
  //border-radius: 12px;
  //box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  //border: 1px solid #eee;
  overflow: hidden;

  .title {
    //padding: 0 10px;
    text-align: center;
  }

  .position {
    width: 120px;
    height: 120px
  }

}

.posterSrc {
  display: block;
  width: 320px;
}
</style>
